.container {
    margin-top: 100px;
    text-align: center;
}

.display-image {
    margin-top: 30px;
    /* text-align: center; */
    /* justify-content: center; */
}

.checkbox-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

.generate-wrapper {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.generate-button {
    width: 100px;
    height: 40px;
    background-color: #94b5b5;
    border: none;
}